<template>
  <ClientOnly>
    <div class="relative h-full sm:px-4">
      <div
        class="container grid lg:grid-cols-[1fr_2fr_1fr] gap-4 pt-4 items-start"
      >
        <div class="hidden lg:block z-10 min-w-0 sticky top-20 space-y-4">
          <div v-if="pending" class="w-full flex flex-col gap-4">
            <Skeleton class="w-full aspect-[16/9]" />
          </div>
          <FeedAsideUserCard
            v-else
            :userEntity="userEntity ?? undefined"
            :following="data?.following ?? 0"
            :followers="data?.followers ?? 0"
          />
          <NamiButton
            v-if="userEntity"
            button-type="primary"
            block
            pill
            @buttonClick="showPostModal = true"
            >{{ $t("site.feed.buttonStartPost") }}
          </NamiButton>
        </div>
        <div class="relative z-20">
          <PageTabs
            class="mb-4 !top-0 sm:!top-16 !relative sm:!sticky"
            :tabs="tabs"
            @change-tab="scrollToTop"
          />
          <Card
            v-if="userEntity && md"
            class="!p-0 sm:my-4 border-b-4 sm:border-b-0 dark:border-black"
          >
            <PostAddEditForm
              :user="userEntity"
              sync
              @post="
                (post) => {
                  appStore?.notify({
                    preset: 'success.plain',
                    detail:
                      post.attributes.state === 'publish_delayed'
                        ? 'Post scheduled.'
                        : post.attributes.state === 'pending_media_upload'
                          ? 'Post will be published once video has finished processing.'
                          : 'Post has been submitted.',
                    timer: 5000,
                  });
                  if (post.attributes.state === 'published')
                    shouldRefresh = !shouldRefresh;
                }
              "
            />
          </Card>
          <NuxtPage keepalive :toggleRefresh="shouldRefresh" />
          <div
            class="sticky bottom-20 sm:bottom-4 float-right mr-4 sm:mr-0 lg:-mr-16 flex flex-col space-y-2"
          >
            <NamiButton
              v-if="scrollPos > 1000 && !sm"
              button-type="primary"
              :icon="IconChevronUp"
              pill
              @buttonClick="scrollToTop"
              title="Back to top"
            />
            <NamiButton
              v-if="!md"
              button-type="primary"
              :icon="IconPencilPlus"
              pill
              @buttonClick="tryPostModal"
              title="Create Post"
            />
          </div>
        </div>
        <div class="hidden lg:block z-10 col-start-3 sticky top-20">
          <NewsFeedList />
        </div>
      </div>
    </div>
    <NamiModal v-model="showPostModal" large inherit>
      <PostAddEditForm
        class="!rounded-lg lg:min-w-[500px] 2xl:min-w-[700px] min-w-[100vw] sm:min-w-0 min-h-screen sm:min-h-0"
        :user="userEntity"
        sync
        textbox-class="min-h-[12rem]"
        @post="
          (post) => {
            appStore?.notify({
              preset: 'success.plain',
              detail:
                post.attributes.state === 'publish_delayed'
                  ? 'Post scheduled.'
                  : post.attributes.state === 'pending_media_upload'
                    ? 'Post will be published once video has finished processing.'
                    : 'Post has been submitted.',
              timer: 5000,
            });
            if (post.attributes.state === 'published')
              shouldRefresh = !shouldRefresh;
            showPostModal = false;
          }
        "
        show-close
        @close="showPostModal = false"
      />
    </NamiModal>
  </ClientOnly>
</template>

<script setup lang="ts">
import { type UserEntity } from "~/src/api";
import { IconChevronUp, IconPencilPlus } from "@tabler/icons-vue";
import { getUserFollowStats } from "~/composables/feed/getUserFollowStats";

definePageMeta({
  layout: "feed",
  title: "Social Feed",
});

const nuxtApp = useNuxtApp();
const translate = nuxtApp.$i18n.global.t;
const locale = nuxtApp.$i18n.global.locale;
const authStore = nuxtApp.$auth();
const appStore = nuxtApp.$app();
const route = useRoute();

const userEntity = computed<UserEntity | undefined>(
  () => authStore?.userEntity ?? undefined,
);

const shouldRefresh = ref(false);
const showPostModal = ref(false);
const { sm, md, lg } = useBreakpoints();

const scrollPos = ref(0);

const tryPostModal = () => {
  if (!authStore?.user) {
    return appStore?.openLoginRequiredModal();
  }
  showPostModal.value = true;
};

const tabs = {
  current: "",
  default: "",
  rootPath: `/${route.params.locale}/feed`,
  items: [
    {
      name: translate("feed.tabEveryone"),
      slug: "",
    },
    {
      name: translate("feed.tabFollowing"),
      slug: "following",
    },
  ],
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
  });
};

const updateScrollPos = () => {
  scrollPos.value = window.scrollY;
};
onMounted(() => window.addEventListener("scroll", updateScrollPos));
onBeforeUnmount(() => window.removeEventListener("scroll", updateScrollPos));

const { pending, data } = useAsyncData(
  "user-follows",
  async () => {
    await authStore?.waitUntilRefreshIsComplete();
    return await getUserFollowStats(authStore?.userEntity?.id);
  },
  { server: false },
);

const config = useRuntimeConfig();
const title = ref("Social Feed");
const description = ref(
  "Interact with fans and creators on NamiComi's social media.",
);
const canonical = ref(`${config.public.baseUrl}/en/feed`);

useHead({
  title: title,
  link: [{ rel: "canonical", href: canonical }],
  meta: [
    {
      property: "og:title",
      content: `${title.value} - ${config.public.siteName}`,
    },
    { name: "description", content: description },
  ],
});
</script>

<style scoped lang="postcss"></style>
