<template>
  <nav
    class="isolate sticky top-16 z-10 flex divide-x divide-neutral-200 dark:divide-neutral-800 whitespace-nowrap tile bg-transparent p-0 rounded-none sm:rounded-md shadow-none sm:shadow"
    aria-label="Tabs"
    ref="pageTabs"
  >
    <TheNuxtLink
      v-for="(tab, tabIdx) in tabs.items"
      :key="tab.name"
      :to="tab.slug ? `${tabs.rootPath}/${tab.slug}` : tabs.rootPath"
      @click="changeTab(tab.slug, tabIdx)"
      class="overflow-ellipsis overflow-hidden text-neutral-500 hover:text-neutral-700 hover:dark:text-neutral-300"
      active-class="text-neutral-900 dark:text-neutral-100"
      :class="[
        tabIdx === 0 ? 'sm:rounded-l-md' : '',
        tabIdx === tabs.items.length - 1 ? 'sm:rounded-r-md' : '',
        'group relative min-w-0 flex-1 overflow-hidden p-4 text-sm sm:text-base font-base text-center transition-all bg-white/80 dark:bg-neutral-900/80 backdrop-blur-lg',
      ]"
      :aria-current="tabs.current === tab.slug ? 'page' : undefined"
    >
      <span>{{ tab.name }}</span>
      <span
        aria-hidden="true"
        :class="[
          tabs.current === tab.slug
            ? 'bg-nami-comi-blue'
            : 'bg-transparent group-hover:bg-nami-nami-blue group-active:bg-nami-deep-blue',
          'absolute inset-x-0 bottom-0 h-0.5 ',
        ]"
      />
    </TheNuxtLink>
  </nav>
</template>

<script setup lang="ts">
import { useElementBounding } from "@vueuse/core";

type Tab = {
  name: string;
  slug: string;
};

interface Props {
  tabs: {
    current: string;
    default: string;
    rootPath: string;
    items: Tab[];
  };
}

interface Events {
  (e: "changeTab", v: string): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Events>();
const route = useRoute();
const startingPos = ref(0);

const pageTabs = ref<HTMLDivElement>();
const tabItems = ref<any[]>([]);

const { top } = useElementBounding(pageTabs);

const tabs = ref(props.tabs);

function changeTab(selectedTab: string, index: number) {
  emit("changeTab", selectedTab);

  (tabItems.value.at(index)?.$el as HTMLAnchorElement)?.scrollIntoView({
    behavior: "smooth",
    block: "nearest",
  });

  if (!pageTabs.value?.getBoundingClientRect()) return;

  if (window.scrollY > startingPos.value) {
    window.scrollTo({
      top: startingPos.value,
      behavior: "smooth",
    });
  }
}

watch(
  () => route.path,
  (v) => {
    const found = props.tabs.items.find(
      (tab) =>
        (tab.slug
          ? `${props.tabs.rootPath}/${tab.slug}`
          : props.tabs.rootPath) === v,
    );
    if (found) {
      tabs.value.current = found.slug;
    } else {
      navigateTo({
        path: `${props.tabs.rootPath}/${
          tabs.value.default ?? tabs.value.items[0].slug
        }`,
        replace: true,
      });
    }
  },
  { immediate: true },
);

onMounted(() => {
  startingPos.value = top.value;
});
</script>
