<template>
  <Card class="space-y-4 !p-4">
    <div class="flex items-center">
      <h1 class="text-base xl:text-xl font-medium flex-grow">
        <TheNuxtLink :href="`/${locale}/news`">
          {{ $t("components.newsFeedList.title") }}
        </TheNuxtLink>
      </h1>
    </div>
    <div v-if="pending" class="space-y-4">
      <Skeleton class="w-full h-24" v-for="n in 6" />
    </div>
    <div v-else class="space-y-6 xl:space-y-8">
      <div v-if="news" class="space-y-4">
        <NewsCard
          v-for="item in news"
          :id="item?.id"
          :cover="item?.cover!"
          :title="item?.title.rendered"
          :slug="item?.slug"
          :sidebar="true"
        />
      </div>
    </div>
  </Card>
</template>

<script setup lang="ts">
import axios from "axios";
import { IconArrowRight } from "@tabler/icons-vue";

const nuxtApp = useNuxtApp();
const locale = nuxtApp.$i18n.global.locale;

defineProps<{
  noLink?: boolean;
  moreNews?: boolean;
}>();

const {
  pending,
  data: news,
  error,
} = useAsyncData("news-showcase", async () => {
  const response = await axios<
    {
      id: number;
      _links: {
        "wp:featuredmedia": {
          href: string;
        }[];
      };
      title: {
        rendered: string;
      };
      excerpt: {
        rendered: string;
      };
      cover?: string;
      slug: string;
    }[]
  >("https://nami.news/wordpress/wp-json/wp/v2/posts?per_page=6", {
    responseType: "json",
  });

  const news = response.data;

  const covers = await Promise.all(
    news.map((newsItem) =>
      axios<{
        media_details: {
          sizes: {
            full: {
              source_url: string;
            };
          };
        };
      }>(newsItem._links["wp:featuredmedia"][0].href),
    ),
  );

  covers.forEach(
    (cover, index) =>
      (news[index].cover = cover.data.media_details.sizes.full.source_url),
  );

  return news;
});
</script>
